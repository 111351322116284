.brand-divider-bottom {
    border-bottom: 1px solid #F2F2F2;
}

.transform-center-y {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

// animations and effects
.hover-underline-animation {
    display: inline-block;
    position: relative;
}

.hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $secondary-color;
    transform-origin: bottom right;
    transition: transform .3s ease-in;
}

.hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.active-link {
    display: inline-block;
    position: relative;
}

.active-link::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $secondary-color;
    transform: scaleX(1);
    transform-origin: bottom left;
}