@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-date-range/dist/styles.css'; // main style file
@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import 'react-date-range/dist/theme/default.css'; // theme css file

@import './styles/constants';
@import './styles/utility';
@import './components/global-navbar/global-navbar.scss';
@import './components/ux/input/input.scss';
@import './components/ux/data-range-picker/date-range-picker.scss';

body {
  overflow: hidden;
}

::-webkit-scrollbar {
  display: none;
}

#root {
  overflow: hidden;
}
input:focus,
textarea:focus {
  outline: none;
}

input:focus::placeholder {
  color: transparent;
}

#dateInput {
  text-align: center;
}

#phoneNumber {
  height: 100%;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  padding-left: 1rem;
}

.ReactModal__Overlay {
  z-index: 20 !important;
}
